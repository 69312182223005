.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.text-rerun {
  color: aqua;
  text-size-adjust: auto;
  text-shadow: 2px 2px black;
  background-color: rgba(255, 255, 255, 50%);
  padding-top: 0.5%;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 1%;
  border-radius: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width:769px){
  body {
    background-image: url('/images/eula1.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
@media (max-width:768px){
  body {
    background-image: url('/images/eula4.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
